<template>
  <div class="public-body">
    <div class="public-body__block  public-body__block--decor">
      <img src="@/assets/img/club-ruy-logo.svg" alt="logo"/>
    </div>
    <div class="public-body__block">
      <div class="public-body__part">
        <h2 class="public-body__title title">Регистрация</h2>
        <div class="require-text">
          <sup>*</sup> - поля обязательные для заполнения
        </div>
        <form @submit.prevent="onCheckForm">
          <app-form-group label-for="lastname" label="Фамилия" required>
            <app-input
              id="lastname"
              name="lastname"
              placeholder="Иванов"
              v-model.trim="form.last_name"
              @change.native="$v.form.last_name.$touch()"
              :error="$v.form.last_name.$error"
            />
            <template #error>
              <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.required">Обязательное поле</div>
              <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.ruAlpha">
                Принимаются только буквы русского алфавита
              </div>
            </template>
          </app-form-group>
          <app-form-group label-for="first_name" label="Имя" required>
            <app-input
              id="first_name"
              name="first_name"
              placeholder="Алексей"
              v-model.trim="form.first_name"
              @change.native="$v.form.first_name.$touch()"
              :error="$v.form.first_name.$error"
            />
            <template #error>
              <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required">Обязательное поле</div>
              <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.ruAlpha">
                Принимаются только буквы русского алфавита
              </div>
            </template>
          </app-form-group>
          <app-form-group label-for="patronymic" label="Отчество (при наличии)">
            <app-input
              id="patronymic"
              name="patronymic"
              placeholder="Петрович"
              v-model.trim="form.patronymic"
              @change.native="$v.form.patronymic.$touch()"
              :error="$v.form.patronymic.$error"
            />
            <template #error>
              <div v-if="$v.form.patronymic.$dirty && !$v.form.patronymic.ruAlpha">
                Принимаются только буквы русского алфавита
              </div>
            </template>
          </app-form-group>
          <app-form-group label-for="dob" label="Дата рождения" required>
            <app-input
              id="dob"
              v-model="form.dob"
              v-mask="'99.99.9999'"
              :placeholder="`Например: ${new Date().toLocaleString('ru').slice(0, 10)}`"
              autocomplete="off"
              class="input"
              :error="$v.form.dob.$error || $v.age.$invalid"
              @input.native="onCheckBirth"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.dob.$dirty && !$v.form.dob.required">Обязательное поле</div>
              <div v-if="$v.form.dob.$dirty && !$v.form.dob.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.dob.$dirty && !$v.age.between">Возраст для регистрации: от 14 до 90 лет{{ age ? `, текущий возраст: ${age} лет` : '' }}</div>
            </template>
          </app-form-group>
          <app-form-group label="Пол" required>
            <app-cells position="start" :indent="false">
              <label class="radio">
                <span class="radio__text">мужской</span>
                <input
                  v-model.number="form.gender"
                  type="radio"
                  :value="true"
                />
                <span class="radio__radiomark"></span>
              </label>
              <label class="radio">
                <span class="radio__text">женский</span>
                <input
                  v-model.number="form.gender"
                  type="radio"
                  :value="false"
                />
                <span class="radio__radiomark"></span>
              </label>
            </app-cells>
            <template #error>
              <div v-if="$v.form.gender.$dirty && !$v.form.gender.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Являетесь ли вы участником Программы «Российская студенческая весна»?" required>
            <app-cells position="start" :indent="false">
              <label class="radio">
                <span class="radio__text">да</span>
                <input
                  v-model.number="form.is_studvesna_member"
                  type="radio"
                  :value="true"
                />
                <span class="radio__radiomark"></span>
              </label>
              <label class="radio">
                <span class="radio__text">нет</span>
                <input
                  v-model.number="form.is_studvesna_member"
                  type="radio"
                  :value="false"
                />
                <span class="radio__radiomark"></span>
              </label>
            </app-cells>
            <template #error>
              <div v-if="$v.form.is_studvesna_member.$dirty && !$v.form.is_studvesna_member.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="email" label="Адрес электронной почты" required>
            <app-input
              v-model="form.email"
              id="email"
              placeholder="ivan@domain.ru"
              :error="$v.form.email.$error"
              @change.native="$v.form.email.$touch()"
            />
            <template #error>
              <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
              <div v-if="$v.form.email.$dirty && !$v.form.email.email">
                Введите правильный формат почты
              </div>
            </template>
          </app-form-group>
          <app-form-group label-for="email_repeat" label="Повторите адрес электронной почты" required>
            <app-input
              v-model="form.email_repeat"
              id="email_repeat"
              placeholder=""
              :error="$v.form.email_repeat.$error"
              @change.native="$v.form.email_repeat.$touch()"
              @paste.native.prevent
              autocomplete="autocomplete_off_email"
            />
            <template #error>
              <div v-if="$v.form.email_repeat.$dirty && !$v.form.email_repeat.required">Обязательное поле</div>
              <div v-if="$v.form.email_repeat.$dirty && !$v.form.email_repeat.email">
                Введите правильный формат почты
              </div>
              <div v-if="$v.form.email_repeat.$dirty && !$v.form.email_repeat.sameAsEmail">
                Электронная почта не соответствует указанной выше
              </div>
            </template>
          </app-form-group>
          <app-form-group label="Телефон" required>
            <div class="form-group__oye">
              <app-phone
                v-model="form.phone"
                placeholder="Введите номер телефона"
                :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
                @change.native="$v.form.phone.$touch()"
                @paste.native.prevent
                :disabled="is_check_phone || is_code_success"
              />
              <template>
                <app-button
                  v-if="!is_check_phone && !is_code_success"
                  @click="onCheckPhone"
                  size="link"
                  theme=""
                  type="button"
                  :disabled="form.phone === '' || form.phone === 0"
                  :class="{ 'btn--error': $v.form.phone_code.$error }"
                >
                  <img v-if="$v.form.phone_code.$error" src="@/assets/img/public/edit-sq-red-icon.svg" alt="Edit icon">
                  <img v-else src="@/assets/img/public/edit-sq-icon.svg" alt="Edit icon">
                  Подтвердить
                </app-button>
                <app-button v-if="is_check_phone" @click="onCancelCheckPhone" size="link" type="button">
                  <img src="@/assets/img/cancel-icon.svg" alt="Edit icon">
                  Отменить
                </app-button>
              </template>
            </div>
            <template #error>
              <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
              <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">
                Неправильный формат номера
              </div>
              <div v-if="$v.form.phone.$dirty && $v.form.phone_code.$error">
                Подтвердите номер телефона
              </div>
            </template>
          </app-form-group>
          <app-form-group v-if="is_check_phone" label-for="phone_code" label="Подтверждение номера телефона" required>
            <app-input
              v-model.trim="form.phone_code"
              id="phone_code"
              placeholder="Код из SMS"
              :error="$v.form.phone_code.$error"
              @change.native="$v.form.phone_code.$touch()"
            />
            <template #error>
              <div v-if="$v.form.phone_code.$dirty && !$v.form.phone_code.required">Обязательное поле</div>
            </template>
            <div class="form-group__text">
              На номер <b>{{ form.phone }}</b> будет совершен звонок.
              <br>
              Введите последние 4 цифры входящего номера телефона.
            </div>
            <div class="form-group__text" v-if="current_time > 0">Повторный запрос будет доступен через <b>{{ current_time }} секунд</b></div>
            <div class="form-group__text" v-else>
              <app-button @click="onCheckPhone" size="link" type="button">
                Запросить новый код
              </app-button>
            </div>
            <div class="form-group__error" v-if="$v.form.phone_code.$dirty && !$v.form.phone_code.minLength">
              Код должен состоять не менее чем из 4-х символов
            </div>
            <div class="form-group__error" v-if="$v.form.phone_code.$dirty && !$v.form.phone_code.maxLength">
              Код должен состоять не более чем из 4-х символов
            </div>
          </app-form-group>
          <app-form-group label-for="password" label="Пароль" required>
            <template #additional>
              <app-button
                @click="onPassGen"
                size="link"
                type="button"
              >
                Сгенерировать пароль
              </app-button>
            </template>
            <app-input
              v-model="form.password"
              type="text"
              id="new_password"
              placeholder="Должен содержать не менее 8 символов"
              :error="$v.form.password.$error"
              @change.native="$v.form.password.$touch()"
            />
            <template #error>
              <div v-if="$v.form.password.$dirty && !$v.form.password.required">Обязательное поле</div>
              <div v-if=" $v.form.password.$dirty && !$v.form.password.minLength">
                Длина пароля менее 8 символов
              </div>
              <div v-if="$v.form.password.$dirty && !$v.form.password.enAlpha">
                Принимаются только буквы английского алфавита и цифры
              </div>
            </template>
          </app-form-group>
          <app-form-group label-for="password_repeat" label="Повторите пароль" required>
            <app-input
              v-model="form.password_repeat"
              type="text"
              id="new_password_repeat"
              placeholder=""
              :error="$v.form.password_repeat.$error"
              @change.native="$v.form.password_repeat.$touch()"
              @paste.native.prevent
              autocomplete="autocomplete_off_password"
            />
            <template #error>
              <div v-if="$v.form.password_repeat.$dirty && !$v.form.password_repeat.required">Обязательное поле</div>
              <div v-if="$v.form.password_repeat.$dirty && !$v.form.password_repeat.sameAsPassword">
                Пароль не соответствует указанному выше
              </div>
              <div v-if=" $v.form.password_repeat.$dirty && !$v.form.password_repeat.minLength">
                Длина пароля менее 8 символов
              </div>
              <div v-if="$v.form.password_repeat.$dirty && !$v.form.password_repeat.enAlpha">
                Принимаются только буквы английского алфавита и цифры
              </div>
            </template>
          </app-form-group>
          <app-form-group>
            <label class="checkbox">
              <span class="checkbox__text">
                Я принимаю условия <router-link :to="{ name: 'agreement' }" target="_blank">Пользовательского соглашения</router-link>, даю своё согласие на обработку <router-link :to="{ name: 'privacy' }" target="_blank">персональных данных</router-link> и ознакомился с Политикой в отношении обработки персональных данных, размещенных на сайте РСМ (раздел <a href="https://www.ruy.ru/docs/" target="_blank">«Документы»</a>).<sup>*</sup>
              </span>
              <input
                type="checkbox"
                v-model="form.policy"
              />
              <span class="checkbox__checkmark"></span>
            </label>
            <template #error>
              <div v-if="$v.form.policy.$dirty && !$v.form.policy.sameAs">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group>
            <label class="checkbox">
              <div class="checkbox__text">
                Я согласен (на) получать рекламную информацию
              </div>
              <input type="checkbox" name="policy" v-model="form.commercial" />
              <div class="checkbox__checkmark"></div>
            </label>
          </app-form-group>
          <app-cells position="left">
            <app-button ref="submit" :disabled="$v.form.$error">
              Зарегистрироваться
            </app-button>
          </app-cells>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers, sameAs, email, minLength, maxLength, between } from 'vuelidate/lib/validators'
import {differenceInCalendarYears, parse} from 'date-fns'

const ruAlpha = helpers.regex('ruAlpha', /^[а-яёА-ЯЁ\s]*$/)
const enAlpha = helpers.regex('ruAlpha', /^[a-zA-Z0-9\s]*$/)
const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'Registration',
  data() {
    return {
      form: {
        last_name: '',
        first_name: '',
        patronymic: '',
        dob: '',
        gender: null,
        is_studvesna_member: null,
        email: '',
        email_repeat: '',
        phone: '',
        password: '',
        password_repeat: '',
        policy: null,
        commercial: true
      },
      age: 30,
      is_check_phone: false,
      timer: null,
      current_time: 0,
      is_code_success: false
    }
  },
  validations: {
    form: {
      last_name: { required, ruAlpha },
      first_name: { required, ruAlpha },
      patronymic: { ruAlpha },
      dob: { required, underscorePresent },
      gender: { required },
      is_studvesna_member: { required },
      email: { required, email },
      email_repeat: { required, email, sameAsEmail: sameAs('email') },
      phone: { required },
      phone_code: { required, minLength: minLength(4), maxLength: maxLength(4) },
      password: { required, minLength: minLength(8), enAlpha },
      password_repeat: { required, minLength: minLength(8), sameAsPassword: sameAs('password'), enAlpha },
      policy: { sameAs: val => val === true },
    },
    age: { between: between(14, 90) }
  },
  watch: {
    current_time(time) {
      if (time === 0) {
        this.stopTimer()
      }
    },
    'form.phone_code'(value) {
      if (value.length === 4) {
        this.onSendPhoneCode()
      }
    }
  },
  methods: {
    onCheckBirth() {
      this.$v.form.dob.$touch()
      const date = parse(this.form.dob, 'dd.MM.yyyy', new Date())
      this.age = differenceInCalendarYears(new Date(), date)
    },
    onPassGen() {
      this.form.password = ''
      this.form.password_repeat = ''
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      for (let i = 0, n = charset.length; i < 8; i++) {
        this.form.password += charset.charAt(Math.floor(Math.random() * n))
      }
      this.form.password_repeat = this.form.password
    },
    onCheckPhone() {
      this.$store.dispatch('auth/CHECK_PHONE_CODE', { phone: this.form.phone, email: this.form.email, method: 'call' })
        .then(response => {
          if (!response.data.status) {
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: 'Что-то пошло не так. Попробуйте позднее.'
            })
          } else {
            this.is_check_phone = true
            this.current_time = 120
            this.startTimer()
            this.$notify({
              type: 'success',
              title: 'Успешно',
              text: 'На ваш телефон поступит входящий вызов.'
            })
          }
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    onCancelCheckPhone() {
      this.is_check_phone = false
      this.current_time = 120
      this.stopTimer()
    },
    startTimer() {
      if (this.current_time > 0) {
        this.timer = setInterval(() => {
          this.current_time--
        }, 1000)
      }
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    onSendPhoneCode() {
      if (this.form.phone_code.length === 4) {
        this.$store.dispatch('auth/POST_PHONE_CODE',
          {
            phone: this.form.phone,
            code: this.form.phone_code
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Успешно',
              text: 'Номер телефона подтвержден'
            })
            this.onCancelCheckPhone()
            this.is_code_success = true
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: 'Что-то пошло не так. Попробуйте позже.'
            })
          })
      }
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('auth/POST_REGISTRATION', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          const user_details = {
            phone: this.form.phone,
            password: this.form.password
          }
          this.$store.dispatch('auth/LOGIN_JWT', { user_details })
            .then(() => {
              this.$router.push({ name: 'profile' })
              if (this.$store.state.sidebarOpen) this.$store.commit('sidebarMobileToggle')
            })
            .catch(error => {
              this.$notify({
                type: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
